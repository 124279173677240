import './App.css';

import BODALogo from './images/BODAV2Logo.png'

import DiscordLogo from './images/discord-white.png'
import FacebookLogo from './images/facebook.png'
import TwitterLogo from './images/twitter.png'
import InstagramLogo from './images/instagram-white.png'
import TelegramLogo from './images/telegram.png'
import CertikLogo from './images/certik.svg'

const Socials = [
    {
        logo: TwitterLogo,
        uri: 'https://twitter.com/BodaToken'
    },
    {
        logo: FacebookLogo,
        uri: 'https://www.facebook.com/BodaToken'
    },
    {
        logo: InstagramLogo,
        uri: 'https://www.instagram.com/bodatoken/'
    },
    {
        logo: DiscordLogo,
        uri: 'https://discord.gg/XMF4UctSnv'
    },
    {
        logo: TelegramLogo,
        uri: 'https://t.me/Boda_Token'
    }
]

function App() {
    return (
        <>
            <header>
                <img src={BODALogo} alt=''/>
            </header>
            <div className="App">
                <h1>Welcome To Boda Token</h1>

                <img className='certik-logo' src={CertikLogo} alt='' onClick={() => window.location = 'https://www.certik.com/projects/boda-token'}/>

                <h2>We Have Moved To A New Website To Host Our <span style={{whiteSpace: 'nowrap'}}>Web3 Applications</span></h2>

                <a href='https://bodatoken.app/' style={{all: 'unset'}}><button>CLICK HERE TO VISIT BODATOKEN.APP</button></a>

                <div className='socials-container'>
                    {
                        Socials.map((x, idx) => 
                            <a key={idx} 
                                className='socials-button' 
                                href={x.uri} 
                                target='_blank' 
                                rel="noreferrer">
                                    <img src={x.logo} alt=''/>
                            </a>   
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default App
